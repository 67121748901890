import React, { Component } from "react"
import { Link } from 'gatsby';
import "../assets/fonts/font.css"
import "../assets/css/faq.css"
import Footer from '../components/footer';

import lineImage from "../images/line2.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import plan1 from "../images/newsec/plan3.png"
import plan2 from "../images/newsec/plan2.png"
import plan3 from "../images/newsec/plan1.png"
import plan4 from "../images/newsec/thump.png"


class Protect extends Component {

  render() {
    return (
      <Layout rest={this.props} layoutType="main">
        <SEO title="Protect Your Vehicle, Protect Your Budget" keywords={[`Protect`, `Vehicle`, `Budget`]} />
        
        <section className="faq-page-container protect">
          <h2 className="protect-title">Protect Your Vehicle, Protect Your Budget</h2>
          <div className="col2out">
          <div className="col2smal"><img src={plan1} /></div>
          <div className="col2big">
            <p className="plantit" >Vehicle Service Contract (VSC)</p>
            <p>Every vehicle comes with a manufacturer’s warranty. These generally expire after 3 years or 36,000 miles from the date of purchase. Once your vehicle eclipses its factory warranty, you may be responsible for the cost of all repairs when mechanical failures occur.</p>
            <p>A Vehicle Service Contract provides worry-free protection against the unexpected expense and inconvenience of breakdowns and costly repairs that may be required after your warranty expires. In addition, a VSC covers the mechanical portion of your vehicle, which auto insurance does not. </p>
            <p>With four levels of coverage and selectable additional options, you choose the plan that best fits your needs. Convenient 24-hour roadside assistance and rental reimbursement are included with most plans. </p>
            <p>If flexibility, peace of mind, and affordable monthly subscription coverage are solutions you’re looking for, our Vehicle Service Contract is the perfect addition to your vehicle investment.</p>
            <p id="lease">Now including the Stacks Reward Plan! Learn more <Link to="/faq/#stack"> here.</Link> </p>
          </div>
          
          </div>

          <div className="col2out" >
          <div className="col2smal fmob"><img src={plan2} /></div>
          <div className="col2big">
            <p className="plantit">Lease Care Ultimate Vehicle Service Contract</p>
            <p>The allure of leasing a new vehicle is the promise of a largely carefree experience, but unexpected breakdowns and everyday wear-and-tear not covered by your lease agreement can be costly. </p>

            <p>You may not have realized the value of adding a Vehicle Service Contract at the time you signed your lease agreement, but don’t miss this chance to fully understand the benefits available to you now. </p>
            <p>Lease Care Ultimate protection, starting at just $39 per month, includes:
              <ul className="mini">
                <li><b>Comprehensive coverage</b> for the mechanical breakdown of your vehicle, bridging gaps left by the manufacturer’s warranty and your car insurance.</li>
                <li><b>Fully pre-paid maintenance</b> that covers up to two service visits per year — reflecting up to a total of $800 in value! </li>
                <li>Additional protections for such common issues as 
                  <b> windshield damage, flat tires, and minor dent repair</b>. </li>
                <li><b>Specialty protections</b> on audio equipment, interior fabric, and more.</li>
                <li>Valuable extra perks including emergency roadside assistance, rental car coverage, and complimentary ID Theft Protection for yourself and your family</li>
              </ul>
            </p>

            <p>For a low monthly subscription fee, Lease Care provides financial protection and peace of mind by covering the cost of unexpected repairs and keeping you on the road. </p>
            <p id="theft">Now including the Stacks Reward Plan! Learn more<Link to="/faq/#stack"> here.</Link></p>
          </div>
          <div className="col2smal fdesk"><img src={plan2} /></div>
          </div>


          <div className="col2out" >
          <div className="col2smal"><img src={plan4} /></div>
          <div className="col2big">
            <p className="plantit" >Valuable ID Theft Protection Included </p>
            <p>Identity theft and identity fraud threats are a serious concern for everyone who spends time on the internet to work, learn, shop, or socialize. ID Theft Protection, included at no cost with any Vehicle Service Contract or Lease Care Ultimate Vehicle Service Contract, provides peace of mind through robust protection and real, tangible remedy should identity theft or fraud occur.</p>
            <p id="tirewheel">ID Theft Protection offers a comprehensive suite of high-value protective services and benefits, including dark web and financial monitoring, identity threat alerts, single bureau credit monitoring, stolen funds reimbursement, and up to $1 million in identity theft insurance. Best-in-class fraud prevention and remediation services, paired with 24/7 customer service, make ID Theft Protection an outstanding solution for the protection of the sensitive and vulnerable digital lives of each member of the family.</p>
          
          </div>
          </div>    


          <div className="col2out" >
          <div className="col2smal fmob"><img src={plan3} /></div>
          <div className="col2big">
            <p className="plantit">Tire & Wheel Protection</p>
            <p>Your tires are one of the most critical components of your vehicle and one of the most susceptible to damage. Road hazards such as nails, glass, branches, debris, and potholes cause damage that can require repair with a simple patch or a full replacement. </p>
            <p>Our Tire & Wheel Service Contract offers three high-value options that can cover the cost of repair or replacement of tires only, tires and wheels, or both tires and wheels with cosmetic damage coverage that extends protection to the outside face of the wheel. The plan even includes 24-hour emergency towing.</p>
            <p>When it comes to tires and wheels, even minor mishaps can add up to major headaches. This monthly subscription, with the option to cancel at any time, is a smart choice to safeguard your vehicle and your budget.</p>

            <p>For a low monthly subscription fee, Lease Care provides financial protection and peace of mind by covering the cost of unexpected repairs and keeping you on the road. </p>
            <p id="tirewheel">Now including the Stacks Reward Plan! Learn more<Link to="/faq/#stack"> here.</Link></p>
          </div>
          <div className="col2smal fdesk"><img src={plan3} /></div>
          </div>


        </section>

        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
    );
  }
}


export default Protect
